import React from "react";

import { makeStyles } from "@material-ui/core";

import {
  DeleteButton,
  SaveButton,
  Toolbar,
  useNotify,
  useRefresh,
  useRedirect,
  useResourceContext,
} from "react-admin";

/**
 * This is the workaround for the issue with the delete operation
 * where it would immediately execute a GET_ONE on the deleted item:
 * https://github.com/marmelab/react-admin/issues/5541
 *
 * Using the setTimeout seems to fix the issue, as defined by this comment:
 * https://github.com/marmelab/react-admin/issues/5541#issuecomment-729090058
 */

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const SimpleToolbar = (props) => {
  const classes = useStyles();
  const { basePath, redirect: redirectTo = "list" } = props;
  const resource = useResourceContext(props);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const deleteRedirect = props.deleteRedirect ?? redirectTo;

  return (
    <Toolbar className={classes.container} {...props}>
      <SaveButton />
      <DeleteButton
        onSuccess={() => {
          notify("ra.notification.deleted", "info", { smart_count: 1 }, true);
          redirect(deleteRedirect, basePath || `/${resource}`);
          setTimeout(refresh, 500);
        }}
      />
    </Toolbar>
  );
};
