import React from "react";

import { Paper, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import Alert from "@material-ui/lab/Alert";

import "./AuthStyles.scss";
import { authenticationService } from "./auth.service";

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Verification code is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});

export class SetNewPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };
  }

  componentDidMount() {
    const email = this.props.history?.location?.state?.email;

    if (!email) {
      console.warn("email required for setting new password view");
      this.props.history.push("/");
    }
    this.setState({ email });
  }

  setPassword(code, password, passwordConfirm, setErrors, setSubmitting) {
    authenticationService
      .setNewPassword(code, password, passwordConfirm, this.state.email)
      .then(
        (res) => {
          this.props.history.push({ pathname: "/login" });
        },
        (error) => {
          console.log("error: ");
          console.log(error);

          if (error.key === "expired-code") {
            setErrors({ expiredCode: "Code has expired" });
          } else if (error.key === "invalid-code") {
            setErrors({ invalidCode: "Code is invalid" });
          } else if (error.key === "limit-exceeded") {
            setErrors({
              limitExceeded:
                "You have exceeded the number of sign in attempts. Please try again later.",
            });
          }
        }
      )
      .finally(() => setSubmitting(false));
  }

  render() {
    return (
      <Formik
        initialValues={{
          code: "",
          password: "",
          passwordConfirm: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          this.setPassword(
            values.code,
            values.password,
            values.passwordConfirm,
            setErrors,
            setSubmitting
          );
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <div className="form-container">
            <Paper>
              <Form>
                <div className="fields-container">
                  <p className="heading-text">
                    Check your email for a code to set a new password.
                  </p>
                  {Object.keys(errors)
                    .filter((type) =>
                      ["expiredCode", "invalidCode", "limitExceeded"].includes(
                        type
                      )
                    )
                    .map((type, idx) => (
                      <Alert key={idx} severity="error">
                        {errors[type]}
                      </Alert>
                    ))}
                  <Field
                    component={TextField}
                    name="code"
                    label="Verification Code"
                    className="text-field"
                    autoComplete="off"
                  />
                  <Field
                    component={TextField}
                    type="password"
                    label="New Password"
                    name="password"
                    className="text-field"
                  />
                  <Field
                    component={TextField}
                    type="password"
                    label="Re-enter New Password"
                    name="passwordConfirm"
                    className="text-field"
                  />
                  <div className="form-footer">
                    <span className="spacer"></span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      className="form-button"
                      type="submit"
                    >
                      Set Password
                    </Button>
                  </div>
                </div>
              </Form>
            </Paper>
          </div>
        )}
      </Formik>
    );
  }
}
