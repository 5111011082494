import React, { useContext } from "react";

import AddIcon from "@material-ui/icons/Add";
import { makeStyles, Typography } from "@material-ui/core";
import { parse } from "query-string";
import { Link } from "react-router-dom";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Button,
  SelectInput,
  useListContext,
  required,
} from "react-admin";
import { ProjectContext } from "./ProjectShow";
import { SimpleToolbar } from "../components/SimpleToolbar";

const useStyles = makeStyles({
  noteList: {
    padding: "0",
    margin: "0",
  },
  note: {
    backgroundColor: "#f5f5f5",
    padding: "4px 8px",
    marginBottom: "4px",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > span": {
      marginRight: "8px",
    },
  },
  noNotes: {
    color: "grey",
    fontStyle: "italic",
    fontSize: ".9rem",
  },
});

/**
 * parses the search component of a Note URL into the
 * project and task components to be used for
 * submitting and redirection
 */
const parseNoteUrl = (search) => {
  const { project_id: project_id_string, task_id: task_id_string } = parse(
    search
  );

  let source, reference, project_id, task_id;
  if (task_id_string) {
    source = "task_id";
    reference = "tasks";
    task_id = task_id_string;
  } else {
    source = "project_id";
    reference = "projects";
  }
  project_id = project_id_string;
  const redirect = project_id ? `/projects/${project_id}/show` : "/projects";

  return { source, reference, task_id, project_id, redirect };
};

export const NoteEdit = (props) => {
  const { redirect } = parseNoteUrl(props.location.search);
  return (
    <Edit {...props}>
      <SimpleForm
        redirect={redirect}
        variant="outlined"
        toolbar={<SimpleToolbar />}
      >
        <TextInput multiline source="note" />
      </SimpleForm>
    </Edit>
  );
};

export const NoteCreate = (props) => {
  const { source, reference, task_id, project_id, redirect } = parseNoteUrl(
    props.location.search
  );

  const init = task_id ? { task_id } : { project_id };

  return (
    <Create {...props}>
      <SimpleForm initialValues={init} redirect={redirect} variant="outlined">
        <ReferenceInput source={source} reference={reference}>
          <SelectInput optionText="id" disabled />
        </ReferenceInput>
        <TextInput multiline source="note" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export const AddNewNoteButton = ({ record, isStage }) => {
  const project_id = useContext(ProjectContext);
  var search = `?project_id=${project_id}`;
  if (isStage) {
    search += `&task_id=${record.id}`;
  }
  return (
    <Button
      component={Link}
      to={{
        pathname: "/notes/create",
        search: search,
      }}
      label="Add Note"
    >
      <AddIcon />
    </Button>
  );
};

export const EditNoteButton = ({ record }) => {
  const project_id = useContext(ProjectContext);

  return (
    <Button
      component={Link}
      to={{
        pathname: `/notes/${record.id}`,
        search: `?project_id=${project_id}`,
      }}
      label="Edit"
    />
  );
};

export const Note = ({ text, editBtn }) => {
  const classes = useStyles();
  return (
    <div className={classes.note}>
      <Typography variant="body2">{text}</Typography>
      {editBtn}
    </div>
  );
};

export const NotesDisplay = (props) => {
  const { ids, data } = useListContext();
  const classes = useStyles();
  const { type = null } = props;

  if (!ids) {
    return null;
  }

  if (ids.length === 0) {
    // type should be project or note
    if (type) {
      return <span className={classes.noNotes}>No notes for {type}</span>;
    }
    return <span className={classes.noNotes}>No notes available</span>;
  }

  return (
    <div>
      <ul className={classes.noteList}>
        {ids.map((id) => (
          <Note
            key={id}
            text={data[id].note}
            editBtn={<EditNoteButton record={data[id]} />}
          />
        ))}
      </ul>
    </div>
  );
};
