import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import {
  MoreHoriz,
  Done,
  Clear,
  ReportProblemOutlined,
} from "@material-ui/icons";
import { useRecordContext } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 2px",
    border: "1px solid",
    backgroundColor: "inherit",
  },
  notStarted: {
    color: "#3a3a3a",
    "& svg": {
      color: "#3a3a3a",
    },
  },
  onHold: {
    color: "#b7aa00",
    "& svg": {
      color: "#b7aa00",
    },
  },
  inProgress: {
    color: "#0000a0",
    "& svg": {
      color: "#0000a0",
    },
  },
  completed: {
    color: "#118611",
    "& svg": {
      color: "#118611",
    },
  },
}));

const NotStartedChip = () => {
  const classes = useStyles();
  return (
    <Chip
      label="Not Started"
      className={`${classes.notStarted} ${classes.root}`}
      icon={<Clear fontSize="small" />}
    />
  );
};

const OnHoldChip = () => {
  const classes = useStyles();
  return (
    <Chip
      label="On Hold"
      className={`${classes.onHold} ${classes.root}`}
      icon={<ReportProblemOutlined fontSize="small" />}
    />
  );
};

const InProgressChip = () => {
  const classes = useStyles();
  return (
    <Chip
      label="In Progress"
      className={`${classes.inProgress} ${classes.root}`}
      icon={<MoreHoriz fontSize="small" />}
    />
  );
};

const CompletedChip = () => {
  const classes = useStyles();
  return (
    <Chip
      label="Completed"
      className={`${classes.completed} ${classes.root}`}
      icon={<Done fontSize="small" />}
    />
  );
};

export const StatusChipField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const id = record[source];

  const MAP = {
    "not-started": <NotStartedChip />,
    "on-hold": <OnHoldChip />,
    "in-progress": <InProgressChip />,
    completed: <CompletedChip />,
  };

  return MAP[id];
};

StatusChipField.defaultProps = { label: "Status", addLabel: true };
