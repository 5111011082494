import React from "react";

import {
  AssignmentOutlined,
  MapOutlined,
  GroupOutlined,
} from "@material-ui/icons";
import { Route } from "react-router-dom";
import { Admin, Resource } from "react-admin";

import { PhLayout } from "./layout/Layout";
import { authProvider } from "../auth/AuthProvider";
import { LoginPage } from "../auth/Login";
import { ForgotPasswordPage } from "../auth/ForgotPassword";
import { SetNewPasswordPage } from "../auth/SetNewPassword";
import { SetPasswordPage } from "../auth/SetPassword";

import { theme } from "./Theme";
import { JobStageCreate, JobStageEdit } from "./Stages";
import { NoteEdit, NoteCreate } from "./Notes";
import { CustomerCreate, CustomerEdit, CustomerList } from "./Customer";
import { ProjectCreate, ProjectEdit, ProjectList } from "./Project";
import { ProjectShow } from "./ProjectShow";
import {
  DefaultTaskList,
  DefaultTaskEdit,
  DefaultTaskCreate,
} from "./DefaultTasks";
import { dataProvider } from "./dataProvider";
// import { UserSettings } from "./settings/UserSettings";

const routes = [
  <Route
    exact
    path="/forgot-password"
    noLayout
    component={ForgotPasswordPage}
  />,
  <Route
    exact
    path="/set-new-password"
    noLayout
    component={SetNewPasswordPage}
  />,
  <Route exact path="/set-password" noLayout component={SetPasswordPage} />,
  // <Route exact path="/user_settings" component={UserSettings} />,
];

export class Main extends React.Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }

  render() {
    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <Admin
        dataProvider={dataProvider}
        layout={PhLayout}
        customRoutes={routes}
        authProvider={authProvider}
        loginPage={LoginPage}
        theme={theme}
      >
        <Resource
          name="projects"
          list={ProjectList}
          edit={ProjectEdit}
          create={ProjectCreate}
          show={ProjectShow}
          icon={MapOutlined}
          options={{ label: "Projects" }}
        />
        <Resource
          name="customers"
          list={CustomerList}
          edit={CustomerEdit}
          create={CustomerCreate}
          icon={GroupOutlined}
          options={{ label: "Customers" }}
        />
        <Resource name="tasks" edit={JobStageEdit} create={JobStageCreate} />
        <Resource name="notes" edit={NoteEdit} create={NoteCreate} />
        <Resource
          name="default_tasks"
          list={DefaultTaskList}
          edit={DefaultTaskEdit}
          create={DefaultTaskCreate}
          icon={AssignmentOutlined}
          options={{ label: "Common Tasks" }}
        />
      </Admin>
    );
  }
}
