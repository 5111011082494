import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { HttpError } from "react-admin";
import { authHeader, getGroup } from "../auth/auth.service";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  try {
    // TODO set Team-Id dynamically
    options.headers.set("Team-Id", getGroup());
    options.headers.set("Authorization", authHeader().Authorization);
  } catch (error) {
    return Promise.reject(new HttpError("User not authorized", 401));
  }
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      page: page,
      perPage: perPage,
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(headers.get("content-range").split("/").pop(), 10),
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource, params) => {
    if (resource !== "projects" || !params.data.files) {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({ data: json }));
    }

    const newPictures = params.data.files.filter(
      (p) => p.rawFile instanceof File
    );
    const formerPictures = params.data.files.filter(
      (p) => !(p.rawFile instanceof File)
    );

    return Promise.all(newPictures.map(convertFileToBase64))
      .then((base64Pictures) => {
        return base64Pictures.map((picture64) => ({
          src: picture64.src,
          title: `${picture64.title}`,
        }));
      })
      .then((transformedNewPictures) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...params.data,
            files: [...transformedNewPictures, ...formerPictures],
          }),
        }).then(({ json }) => ({ data: json }));
      });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    if (resource !== "projects" || !params.data.files) {
      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }

    return Promise.all(params.data.files.map(convertFileToBase64))
      .then((base64Pictures) => {
        return base64Pictures.map((picture64) => ({
          src: picture64.src,
          title: `${picture64.title}`,
        }));
      })
      .then((transformedNewPictures) => {
        return httpClient(`${apiUrl}/${resource}`, {
          method: "POST",
          body: JSON.stringify({
            ...params.data,
            files: [...transformedNewPictures],
          }),
        }).then(({ json }) => ({ data: json }));
      });
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({
        src: reader.result,
        title: file.title,
      });
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
