import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  required,
  Filter,
  FunctionField,
  ImageInput,
} from "react-admin";
import { DateInput } from "../components/DateInput";
import { TruncatedTextField } from "../components/TruncatedTextField";
import { makeStyles, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { SimpleToolbar } from "../components/SimpleToolbar";
import { StatusChipField } from "../components/StatusChipField";
import { InputDescription } from "../components/InputDescription";
import { ProjectCustomerInput } from "./ProjectCustomerInput";
import { FileOrImageField } from "../components/FileOrImageField";

const useStyles = makeStyles((theme) => ({
  customerPageButton: {
    marginTop: "16px",
  },
  statusFilter: {
    maxWidth: "150px",
    minWidth: "150px",
  },
  customerFilter: {
    maxWidth: "275px",
    minWidth: "275px",
  },
  noPadding: {
    paddingTop: "0",
    paddingBottom: "0",
  },
}));

const dropZoneStyles = makeStyles((theme) => ({
  root: {
    flexGrow: "1",
    width: "350px",
  },
  dropZone: {
    border: "2px dashed #adadad",
  },
  removeButton: {
    display: "flex",
    alignItems: "flex-start",
  },
}));

const STATUS_CHOICES = [
  { id: "not-started", name: "Not Started" },
  { id: "on-hold", name: "On Hold" },
  { id: "in-progress", name: "In Progress" },
  { id: "completed", name: "Completed" },
];

export const ProjectContext = React.createContext(null);

const ProjectListFilters = (props) => {
  const classes = useStyles();

  return (
    <Filter {...props}>
      <TextInput
        source="q"
        alwaysOn
        variant="outlined"
        label="Customer"
        resettable
        className={classes.customerFilter}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon color="disabled" />
            </InputAdornment>
          ),
        }}
      />
      <SelectInput
        source="status"
        choices={STATUS_CHOICES}
        alwaysOn
        variant="outlined"
        className={classes.statusFilter}
        options={{ width: 50 }}
        style={{ width: 50 }}
      />
    </Filter>
  );
};

export const ProjectList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "updated_at", order: "DESC" }}
      filters={<ProjectListFilters />}
      perPage={10}
    >
      <Datagrid rowClick="show" size="medium">
        <TruncatedTextField source="id" length={8} showElipsis={false} />
        <TextField source="name" />
        <ReferenceField source="customer_id" reference="customers">
          <FunctionField
            label="Name"
            render={(record) => `${record.first_name} ${record.last_name}`}
          />
        </ReferenceField>
        <DateField source="date_started" label="Date Started" />
        {/* <DateField source="updated_at" label="Last Updated" /> */}
        <TextField
          source="updated_at_display"
          label="Last Updated"
          sortBy="updated_at"
        />
        <StatusChipField source="status" cellClassName={classes.noPadding} />
      </Datagrid>
    </List>
  );
};

const EnhancedImageInput = ({ record, ...rest }) => {
  const acceptedFormats = [
    "image/*",
    ".pdf",
    ".doc",
    ".docx",
    ".xml",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  return (
    <ImageInput
      source="files"
      label="Files"
      multiple
      classes={dropZoneStyles()}
      accept={acceptedFormats.join()}
      labelMultiple="Drop some images or documents to upload, or click to select one."
      {...rest}
    >
      <FileOrImageField source="path" title="title" />
    </ImageInput>
  );
};

export const ProjectEdit = (props) => (
  <Edit {...props}>
    <SimpleForm
      variant="outlined"
      redirect="show"
      toolbar={<SimpleToolbar deleteRedirect="list" />}
    >
      <InputDescription
        name="Project Name"
        description="Enter a name for the project."
      >
        <TextInput source="name" label="Project Name" variant="outlined" />
      </InputDescription>
      <InputDescription
        name="Description"
        description="A few sentences to describe the project."
      >
        <TextInput multiline source="description" variant="outlined" />
      </InputDescription>
      <InputDescription
        name="Customer"
        description="The Customer that the project belongs to. The Customer can't be changed after the project has been created."
      >
        <ReferenceInput
          source="customer_id"
          reference="customers"
          variant="outlined"
        >
          <SelectInput
            optionText={(record) => `${record.first_name} ${record.last_name}`}
            disabled
          />
        </ReferenceInput>
      </InputDescription>
      <InputDescription
        name="Status"
        description="The current status of the project. Update this as the project progresses."
      >
        <SelectInput
          source="status"
          choices={STATUS_CHOICES}
          variant="outlined"
        />
      </InputDescription>
      <InputDescription
        name="Date Started"
        description="The date that the project was started."
      >
        <DateInput source="date_started" label="Date Started" />
      </InputDescription>
      <InputDescription
        name="Date Completed"
        description="The date that the project was completed."
      >
        <DateInput source="date_completed" label="Date Completed" />
      </InputDescription>
      <InputDescription
        name="Files"
        description="Add images and documents to be shared with Customer. Click an image to set as the primary image. 
        The primary image will be shown prominently on the customer page."
      >
        <EnhancedImageInput />
      </InputDescription>
    </SimpleForm>
  </Edit>
);

export const ProjectCreate = (props) => (
  <Create {...props}>
    <SimpleForm variant="outlined" redirect="show">
      <InputDescription
        name="Project Name"
        description="Enter a name for the project."
      >
        <TextInput
          source="name"
          label="Project Name"
          validate={required()}
          variant="outlined"
        />
      </InputDescription>
      <InputDescription
        name="Description"
        description="Give a short description for the project."
      >
        <TextInput multiline source="description" variant="outlined" />
      </InputDescription>
      <InputDescription
        name="Customer"
        description="Enter the name of the Customer that the project belongs to. You can create a customer if they don't exist yet."
      >
        <ProjectCustomerInput />
      </InputDescription>
      <InputDescription
        name="Status"
        description="Select the current status of the project. You can update this later as the project progresses."
      >
        <SelectInput
          source="status"
          choices={STATUS_CHOICES}
          initialValue="in-progress"
          variant="outlined"
        />
      </InputDescription>
      <InputDescription
        name="Date Started"
        description="Select the date that the project started. You can leave this empty and update this later if it has not yet started."
      >
        <DateInput source="date_started" label="MM/DD/YYYY" />
      </InputDescription>
      <InputDescription
        name="Date Completed"
        description="Select the date that the project was completed. You can leave this empty and update this later after the project finishes."
      >
        <DateInput source="date_completed" label="MM/DD/YYYY" />
      </InputDescription>
      <InputDescription
        name="Files"
        description="Add images and documents to be shared with Customer. Click an image to set as the primary image. 
        The primary image will be shown prominently on the customer page."
      >
        <EnhancedImageInput />
      </InputDescription>
    </SimpleForm>
  </Create>
);
