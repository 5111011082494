import React from "react";

import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
} from "react-admin";
import Alert from "@material-ui/lab/Alert";
import { SimpleToolbar } from "../components/SimpleToolbar";

export const DefaultTaskList = (props) => (
  <>
    <Alert severity="info">
      Define <strong>common tasks</strong> for those tasks that will be used for
      many projects. <br />
      <br />
      They provide a convenient way to create a task without having to define
      the same task over and over. After creating, the common task name and
      description will be available for quick selection when creating project
      tasks.
    </Alert>
    <List {...props} bulkActionButtons={false} perPage={10}>
      <Datagrid rowClick="edit" size="medium">
        <TextField source="name" />
        <TextField source="description" />
      </Datagrid>
    </List>
  </>
);

const CustomInput = ({ source, ...rest }) => {
  return (
    <React.Fragment>
      <TextInput source={source} {...rest} />
    </React.Fragment>
  );
};

export const DefaultTaskEdit = (props) => (
  <Edit {...props}>
    <SimpleForm variant="outlined" toolbar={<SimpleToolbar />}>
      <TextInput source="name" validate={required()} />
      <CustomInput source="description" multiline />
    </SimpleForm>
  </Edit>
);

export const DefaultTaskCreate = (props) => (
  <Create {...props}>
    <SimpleForm variant="outlined" redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="description" multiline />
    </SimpleForm>
  </Create>
);
