import { authenticationService } from "./auth.service";

export const authProvider = {
  login: (params) => {
    return authenticationService.login(params.username, params.password);
  },
  logout: () => {
    localStorage.removeItem("currentUser");
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("currentUser") // use localStorage instead of auth service user value because it was throwing errors
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      console.log("Token not valid, signing out");
      localStorage.removeItem("currentUser");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
};
