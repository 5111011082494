import React from "react";
import "./App.scss";

import { authenticationService, history } from "./auth/auth.service";
import { Main } from "./admin/Main";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({ currentUser: x })
    );
  }

  logout() {
    authenticationService.logout();
    history.push("/login");
  }

  render() {
    return <Main />;
  }
}

export default App;
