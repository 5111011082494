import React, { useState, useCallback } from "react";

import {
  Typography,
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import {
  ReferenceInput,
  AutocompleteInput,
  required,
  useCreate,
  Button,
  SaveButton,
  TextInput,
  useNotify,
  FormWithRedirect,
  email,
} from "react-admin";
import { useForm } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  customerEmail: {
    fontSize: ".9rem",
    color: "grey",
    marginLeft: "4px",
  },
  customerButtom: {
    float: "right",
    marginTop: "-24px",
    fontSize: ".75rem",
  },
  spacing: {
    justifyContent: "space-between",
  },
}));

const CustomerName = ({ record }) => {
  const classes = useStyles();
  const { first_name, last_name, email } = record;
  return (
    <Typography>
      {first_name} {last_name}{" "}
      <span className={classes.customerEmail}>{email}</span>
    </Typography>
  );
};

const inputText = (record) => {
  // Only showing the name for now since inputText is required to be
  // string and showing email in normal font is verbose.
  return `${record.first_name} ${record.last_name}`;
};

const CreateCustomerButton = ({ onChange }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("customers");
  const notify = useNotify();
  const form = useForm();
  const classes = useStyles();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          console.log(data);
          setShowDialog(false);
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change("customer_id", data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button
        onClick={handleClick}
        label="Create Customer"
        className={classes.customerButtom}
      ></Button>
      <Dialog
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create customer"
      >
        <DialogTitle>Create Customer</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  source="first_name"
                  validate={required()}
                  variant="outlined"
                />
                <TextInput
                  source="last_name"
                  validate={required()}
                  variant="outlined"
                />
                <TextInput
                  source="email"
                  validate={[required(), email()]}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions classes={{ spacing: classes.spacing }}>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

/**
 * Input to select customers when creating projects.
 * Provides a create modal to create ad hoc customers.
 */
export const ProjectCustomerInput = () => {
  // Use version increment to trigger refresh of the input values state each time there is an update
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <div>
      <ReferenceInput
        source="customer_id"
        reference="customers"
        validate={required()}
        perPage={100}
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        variant="outlined"
        key={version}
      >
        <AutocompleteInput
          required={true}
          optionText={<CustomerName />}
          inputText={inputText}
          // Only match when there is a filter. I.e., if no filter is given,
          // don't try to fetch any data
          // TODO only match the start of the string, currently matches any postition
          // ex. entering 'a' will match any name with an 'a' in it rather than start of name
          matchSuggestion={(filter, choice) => !!filter}
          suggestionLimit={5}
          resettable
        />
      </ReferenceInput>
      <CreateCustomerButton onChange={handleChange} />
    </div>
  );
};
