import React from "react";

export const TruncatedTextField = ({
  source,
  record = {},
  length = 100,
  showElipsis = true,
}) => {
  const s = record[source] || "";
  const end = showElipsis ? "..." : "";
  const formatted = s.length > length ? s.slice(0, length) + end : s;
  return <span>{formatted}</span>;
};
