import React from "react";
import { FileField, ImageField } from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  frame: {
    display: "inline-flex",
    flexDirection: "column",
    margin: "6px",
    border: "2px solid #f5f5f5",
  },
}));

const imageStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    objectFit: "contain",
    margin: "0",
  },
}));

const docStyles = makeStyles((theme) => ({
  root: {
    padding: "12px",
  },
}));

export const FileOrImageField = ({ record, source, title, ...rest }) => {
  const IMAGE_EXTENSIONS = [".jpeg", ".png", ".jpg"];
  const classes = useStyles();

  const name = record.hasOwnProperty("rawFile")
    ? record.rawFile.name
    : record.title;

  const isImage =
    name && IMAGE_EXTENSIONS.some((ext) => name.toLowerCase().endsWith(ext));

  return isImage ? (
    <div className={classes.frame}>
      <ImageField
        classes={imageStyles()}
        source={source}
        title={title}
        record={record}
        {...rest}
      />
    </div>
  ) : (
    <FileField
      source={source}
      title={title}
      record={record}
      {...rest}
      classes={docStyles()}
    />
  );
};
