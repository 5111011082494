import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "24px",
    flexWrap: "wrap",
  },
  descriptionCol: {
    marginRight: "64px",
    width: "375px",
    minWidth: "180px",
    marginBottom: "12px",
  },
  name: {
    fontSize: ".9rem",
    fontWeight: "600",
    margin: "0",
  },
  description: {
    fontSize: ".9rem",
    color: "#636363",
    fontWeight: "400",
    margin: "0",
    marginTop: "8px",
    lineHeight: "1.4",
  },
}));

export const InputDescription = ({
  name,
  description = null,
  children,
  ...rest
}) => {
  // const { name, description = null, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.descriptionCol}>
        <p className={classes.name}>{name}</p>
        <p className={classes.description}>{description}</p>
      </div>
      {React.Children.map(children, (child) => React.cloneElement(child, rest))}
    </div>
  );
};
