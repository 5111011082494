import React from "react";

import { Paper, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

import { useLogin } from "react-admin";
import { useHistory } from "react-router-dom";

import "./AuthStyles.scss";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const LoginPage = (props) => {
  const login = useLogin();
  const history = useHistory();

  const signIn = ({ email, password }, setErrors, setSubmitting) => {
    login({ username: email, password: password })
      .then(
        (res) => {},
        (error) => {
          console.log("error:");
          console.log(error);

          // This is done in the error handling because the useLogin hook will do a redirect
          // on a successful result. This is a workaround so that we can specify where to redirect to.
          if (error.key === "new-password-required") {
            const { from } = {
              from: {
                pathname: "/set-password",
                state: { session: error.session, userId: error.user_id },
              },
            };
            history.push(from);
          } else if (error.key === "invalid-credentials") {
            setErrors({ invalidCredentials: "Invalid credentials" });
          }
        }
      )
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        signIn(values, setErrors, setSubmitting);
      }}
    >
      {({ submitForm, isSubmitting, errors }) => (
        <div className="form-container">
          <Paper>
            <Form>
              <div className="fields-container">
                {errors.invalidCredentials && (
                  <Alert severity="error">Invalid email or password</Alert>
                )}
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  className="text-field"
                />
                <Field
                  component={TextField}
                  type="password"
                  label="Password"
                  name="password"
                  className="text-field"
                />
                <div className="form-footer">
                  <Link to="/forgot-password">
                    <Button size="small" className="form-button">
                      forgot password
                    </Button>
                  </Link>
                  <span className="spacer"></span>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    className="form-button"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </Paper>
        </div>
      )}
    </Formik>
  );
};
