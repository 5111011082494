import React from "react";

import { Paper, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import Alert from "@material-ui/lab/Alert";

import { authenticationService } from "./auth.service";
import "./AuthStyles.scss";

const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export class ForgotPasswordPage extends React.Component {
  submit(email, setErrors, setSubmitting) {
    authenticationService
      .forgotPassword(email)
      .then(
        (res) => {
          this.props.history.push({
            pathname: "/set-new-password",
            state: { email: email },
          });
        },
        (error) => {
          console.log("error: ");
          console.log(error);

          if (error.key === "limit-exceeded") {
            setErrors({
              limitExceeded:
                "You have exceeded the number of password reset attempts. Please try again later.",
            });
          }
        }
      )
      .finally(() => setSubmitting(false));
  }

  render() {
    return (
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={EmailSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          this.submit(values.email, setErrors, setSubmitting);
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <div className="form-container">
            <Paper>
              <Form>
                <div className="fields-container">
                  {errors.limitExceeded && (
                    <Alert severity="error">{errors.limitExceeded}</Alert>
                  )}
                  <p className="heading-text">
                    Provide your email so we can send you a code to set a new
                    password.
                  </p>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    className="text-field"
                  />
                  <div className="form-footer">
                    <span className="spacer"></span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      className="form-button"
                      type="submit"
                    >
                      Send Code
                    </Button>
                  </div>
                </div>
              </Form>
            </Paper>
          </div>
        )}
      </Formik>
    );
  }
}
