import React from "react";
import { AppBar } from "react-admin";
import { makeStyles } from "@material-ui/core";
// import { PFUserMenu } from "./User";
// import {theme} from "../Theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    fontFamily: "'Merriweather', serif",
    fontSize: "32px",
    fontStyle: "italic",
    color: theme.palette.primary.main,
    margin: "18px",
    fontWeight: "600",
    textShadow: "0px 1px 0px black",
  },
  spacer: {
    flex: "1",
  },
  toolbar: {
    boxShadow: "none",
    backgroundColor: "#2d2d2d",
    borderBottom: "1px solid #8080809e",
    color: "#fff",
  },
}));

export const PhAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar
      position="static"
      color="inherit"
      className={classes.toolbar}
      // userMenu={<PFUserMenu />}
      {...props}
    >
      {/* <p className={classes.logo}>project flare</p> */}
      <img
        className={classes.logo}
        src="logo-light.svg"
        alt="Project Flare logo"
      />
      <span className={classes.spacer}></span>
    </AppBar>
  );
};
