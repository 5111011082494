import React, { useContext, useState, useEffect } from "react";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { parse } from "query-string";
import {
  Button,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useGetList,
  required,
  useInput,
} from "react-admin";
import { DateInput } from "../components/DateInput";
import { ProjectContext } from "./ProjectShow";
import { useForm } from "react-final-form";
import { SimpleToolbar } from "../components/SimpleToolbar";
import { InputDescription } from "../components/InputDescription";

const STATUS_CHOICES = [
  { id: "not-started", name: "Not Started" },
  { id: "in-progress", name: "In Progress" },
  { id: "completed", name: "Completed" },
];

const projectRedirect = (location) => {
  const { project_id: project_id_string } = parse(location.search);
  const project_id = project_id_string;
  const redirect = project_id ? `/projects/${project_id}/show` : "/projects";
  return { redirect, project_id };
};

export const JobStageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm
      variant="outlined"
      redirect={projectRedirect(props.location).redirect}
      toolbar={<SimpleToolbar />}
    >
      <InputDescription name="Task Name" description="The name of the task.">
        <TextInput source="name" multiline variant="outlined" />
      </InputDescription>
      <InputDescription
        name="Description"
        description="Enter a short description for the task."
      >
        <TextInput source="description" multiline variant="outlined" />
      </InputDescription>
      <InputDescription
        name="Status"
        description="The current status of the task. You can update this as the task progresses."
      >
        <SelectInput
          source="status"
          choices={STATUS_CHOICES}
          variant="outlined"
        />
      </InputDescription>
      <InputDescription
        name="Date Started"
        description="Select the date the task was started."
      >
        <DateInput source="date_started" label="MM/DD/YYYY" />
      </InputDescription>
      <InputDescription
        name="Date Completed"
        description="Select the date the task was completed."
      >
        <DateInput source="date_completed" label="MM/DD/YYYY" />
      </InputDescription>
      <InputDescription
        name="Project"
        description="The project the task belongs to. This value is set automatically."
      >
        <ReferenceInput
          source="project_id"
          reference="projects"
          variant="outlined"
        >
          <SelectInput optionText="id" disabled />
        </ReferenceInput>
      </InputDescription>
    </SimpleForm>
  </Edit>
);

/**
 * Fetches the Task names as an array of choices (rather
 * than using reference field) so that the value can be
 * saved as plain text rather than an id.
 *
 * Will also help later when we need to add ad hoc values
 * that are not defined as a default task.
 *
 * After selecting, it will update the description field
 * using the form hook.
 */
const TaskNameField = (props) => {
  const { data, ids, loading } = useGetList("default_tasks", { perPage: 50 });
  const [fields, setFields] = useState([]);
  const form = useForm();
  const {
    input: { name, onChange, ...rest },
    isRequired,
  } = useInput(props);

  useEffect(() => {
    setFields(
      ids.map((id) => {
        return {
          id: id,
          name: data[id].name,
        };
      })
    );
  }, [data, ids]);

  const idFromName = (name) => {
    return fields.find((row) => row.name === name).id;
  };

  const handleSelect = (event) => {
    const name = event.target.value;
    const id = idFromName(name);
    form.change("description", data[id].description);
    onChange(event);
  };

  useEffect(() => {
    console.log(fields.length);
    console.log(fields);
  }, [fields]);

  return ids.length > 0 || loading ? (
    <SelectInput
      name="name"
      variant="outlined"
      source="name"
      choices={fields}
      onChange={handleSelect}
      label="Task Name"
      optionValue="name"
      required={isRequired}
      {...rest}
    />
  ) : (
    <>
      <Alert severity="warning">
        No tasks have been configured.{" "}
        <Link to="/default_tasks">Create one first</Link>
      </Alert>
      <SelectInput {...props} disabled />
    </>
  );
};

export const JobStageCreate = (props) => {
  const { redirect, project_id } = projectRedirect(props.location);
  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{
          project_id,
          status: "not-started",
        }}
        redirect={redirect}
        variant="outlined"
      >
        <InputDescription
          name="Task Name"
          description="Select the task name. The task name must exist as a default task."
        >
          <TaskNameField
            source="name"
            validate={required()}
            variant="outlined"
          />
        </InputDescription>
        <InputDescription
          name="Description"
          description="Enter a short description for the task."
        >
          <TextInput source="description" multiline variant="outlined" />
        </InputDescription>
        <InputDescription
          name="Status"
          description="Select the current status of the task. You can update this later as the task progresses."
        >
          <SelectInput
            source="status"
            choices={STATUS_CHOICES}
            variant="outlined"
          />
        </InputDescription>
        <InputDescription
          name="Date Started"
          description="Select the date the task was started."
        >
          <DateInput source="date_started" label="MM/DD/YYYY" />
        </InputDescription>
        <InputDescription
          name="Date Completed"
          description="Select the date the task was completed."
        >
          <DateInput source="date_completed" label="MM/DD/YYYY" />
        </InputDescription>
        <InputDescription
          name="Project"
          description="The project the task belongs to. This value is set automatically."
        >
          <ReferenceInput
            source="project_id"
            reference="projects"
            variant="outlined"
          >
            <SelectInput optionText="id" disabled />
          </ReferenceInput>
        </InputDescription>
      </SimpleForm>
    </Create>
  );
};

export const AddNewStageButton = () => {
  const project_id = useContext(ProjectContext);

  return (
    <Button
      component={Link}
      to={{
        pathname: "/tasks/create",
        search: `?project_id=${project_id}`,
      }}
      label="Add Task"
      variant="contained"
    >
      <AddIcon />
    </Button>
  );
};

export const EditStageButton = ({ record }) => {
  const project_id = useContext(ProjectContext);

  return (
    <Button
      component={Link}
      to={{
        pathname: `/tasks/${record.id}`,
        search: `?project_id=${project_id}`,
      }}
      label="Edit Task"
      variant="outlined"
    >
      <EditIcon />
    </Button>
  );
};
