import * as React from "react";
import { useSelector } from "react-redux";
import {
  useMediaQuery,
  makeStyles,
  ListItemIcon,
  MenuItem,
} from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";

const useStyles = makeStyles({
  container: {
    paddingTop: "1.5em",
    "& > a": {
      marginBottom: ".35em",
      fontWeight: 600,
      color: "#848484",
      fontSize: "0.9rem",
    },
    "& svg": {
      color: "#6969698a",
      fontSize: "1.3rem",
    },
    backgroundColor: "hsl(0deg 0% 95% / 70%)",
    height: "100%",
  },
});

export const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const classes = useStyles();

  const handleDocsClick = (e) => {
    e.preventDefault();
    window.open("https://docs.projectflare.co", "_blank");
  };

  return (
    <div className={classes.container}>
      {resources
        .filter((resource) => resource.hasList)
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      <MenuItem onClick={handleDocsClick} component="a">
        <ListItemIcon style={{ minWidth: "40px" }}>
          <MenuBookOutlinedIcon />
        </ListItemIcon>
        Docs
      </MenuItem>
      {isXSmall && logout}
    </div>
  );
};
