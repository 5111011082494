import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useListContext, Labeled, ReferenceManyField } from "react-admin";
import { DateField } from "../components/DateField";
import { StatusChipField } from "../components/StatusChipField";
import { NotesDisplay, AddNewNoteButton } from "./Notes";
import { EditStageButton } from "./Stages";

const useStyles = makeStyles((theme) => ({
  taskList: {
    padding: "0",
    margin: "0",
  },
  taskCard: {
    marginBottom: "24px",
  },
  container: {
    padding: "32px 48px",
  },
  section: {
    marginBottom: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
  },
  title: {
    marginBottom: "8px",
  },
  projectId: {
    color: "#5f5f5f",
    "&:not(:last-child)": {
      marginBottom: "12px",
    },
  },
  dateLabel: {
    marginRight: "64px",
    minWidth: "0",
  },
  notesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  footer: {
    backgroundColor: "hsl(0deg 0% 96%)",
    padding: "12px 48px",
  },
  spacer: {
    flexGrow: "1",
  },
}));

export const TaskDisplay = ({ record }) => {
  const classes = useStyles();
  return (
    <Card className={classes.taskCard}>
      <CardContent className={classes.container}>
        <div className={classes.section}>
          <div className={classes.header}>
            <div>
              <Typography variant="h2" className={classes.title}>
                {record.name}
              </Typography>
              {record.description && (
                <Typography>{record.description}</Typography>
              )}
            </div>
            <div>
              <StatusChipField source="status" record={record} />
            </div>
          </div>
        </div>

        <div className={classes.section}>
          <Labeled className={classes.dateLabel} label="Started">
            <DateField source="date_started" record={record} />
          </Labeled>
          <Labeled className={classes.dateLabel} label="Completed">
            <DateField source="date_completed" record={record} />
          </Labeled>
        </div>

        <div className={classes.section}>
          <div className={classes.notesContainer}>
            <Labeled label="Notes">
              <ReferenceManyField
                label="Notes"
                reference="notes"
                target="task_id"
                record={record}
              >
                <NotesDisplay type="task" />
              </ReferenceManyField>
            </Labeled>
            <AddNewNoteButton record={record} isStage />
          </div>
        </div>
      </CardContent>
      <CardActions className={classes.footer}>
        <span className={classes.spacer}></span>
        <EditStageButton record={record} />
      </CardActions>
    </Card>
  );
};

export const TasksList = (props) => {
  const { ids, data } = useListContext();
  const classes = useStyles();

  return (
    <React.Fragment>
      {ids && (
        <ul className={classes.taskList}>
          {ids.map((id) => (
            <TaskDisplay key={id} record={data[id]} />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};
