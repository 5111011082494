import React from "react";

import { Paper, Button } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "formik-material-ui";

import "./AuthStyles.scss";
import { authenticationService } from "./auth.service";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});

export class SetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      session: null,
      userId: null,
    };
  }

  componentDidMount() {
    const session = this.props.history?.location?.state?.session;
    const userId = this.props.history?.location?.state?.userId;

    if (!session || !userId) {
      console.warn("session or userId not provided");
      this.props.history.push("/");
    }
    this.setState({
      session: session,
      userId: userId,
    });
  }

  submit(password, passwordConfirm, setErrors, setSubmitting) {
    authenticationService
      .setPermanentPassword(
        this.state.session,
        this.state.userId,
        password,
        passwordConfirm
      )
      .then(
        (res) => {
          if (res.key === "authenticated") {
            console.log("user is now authenticated");
            this.props.history.push({ pathname: "/" });
          }
          // what about error cases?
        },
        (error) => {
          console.log("error: ");
          console.log(error);

          if (error.key === "invalid-auth") {
            setErrors({
              invalidAuth:
                "Could not confirm password at this time. Please contact admin for a password reset.",
            });
          }
        }
      )
      .finally(() => setSubmitting(false));
  }

  render() {
    return (
      <Formik
        initialValues={{
          password: "",
          passwordConfirm: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          this.submit(
            values.password,
            values.passwordConfirm,
            setErrors,
            setSubmitting
          );
        }}
      >
        {({ submitForm, isSubmitting, errors }) => (
          <div className="form-container">
            <Paper>
              <Form>
                <div className="fields-container">
                  {errors.invalidAuth && (
                    <Alert severity="error">{errors.invalidAuth}</Alert>
                  )}
                  <Field
                    component={TextField}
                    type="password"
                    label="New Password"
                    name="password"
                    className="text-field"
                  />
                  <Field
                    component={TextField}
                    type="password"
                    label="Re-Enter New Password"
                    name="passwordConfirm"
                    className="text-field"
                  />
                  <div className="form-footer">
                    <span className="spacer"></span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      className="form-button"
                      type="submit"
                    >
                      Set Password
                    </Button>
                  </div>
                </div>
              </Form>
            </Paper>
          </div>
        )}
      </Formik>
    );
  }
}
