import React from "react";

import {
  List,
  Datagrid,
  EmailField,
  SimpleForm,
  Edit,
  TextInput,
  Create,
  required,
  email,
  DateField,
  Filter,
  SearchInput,
  FunctionField,
} from "react-admin";
import { SimpleToolbar } from "../components/SimpleToolbar";

const UserFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn variant="outlined" />
  </Filter>
);

export const CustomerList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "updated_at", order: "DESC" }}
    filters={<UserFilter />}
    perPage={10}
  >
    <Datagrid rowClick="edit" size="medium">
      <FunctionField
        label="Name"
        render={(record) => `${record.first_name} ${record.last_name}`}
      />
      <EmailField source="email" color="default" />
      <DateField source="created_at" label="Created" />
    </Datagrid>
  </List>
);

export const CustomerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm variant="outlined" toolbar={<SimpleToolbar />}>
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="email" disabled />
    </SimpleForm>
  </Edit>
);

export const CustomerCreate = (props) => (
  <Create {...props}>
    <SimpleForm variant="outlined" redirect="list">
      <TextInput source="first_name" validate={required()} />
      <TextInput source="last_name" validate={required()} />
      <TextInput source="email" validate={[required(), email()]} />
    </SimpleForm>
  </Create>
);
