import React from "react";

import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { makeStyles } from "@material-ui/core";
import { useRecordContext } from "react-admin";

const useStyles = makeStyles((theme) => ({
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
  dateNotSet: {
    color: "grey",
    fontStyle: "italic",
    fontSize: ".9rem",
  },
  dateIcon: {
    marginRight: "8px",
    color: "#616161",
  },
}));

export const DateField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const classes = useStyles();

  if (!record) {
    return null;
  }

  let content = "";
  if (!record[source]) {
    if (source === "date_started") {
      content = <span className={classes.dateNotSet}>Not yet started</span>;
    } else if (source === "date_completed") {
      content = <span className={classes.dateNotSet}>Not yet completed</span>;
    }
  } else {
    content = <span>{record[source]}</span>;
  }
  return (
    <span className={classes.dateContainer}>
      <CalendarTodayOutlinedIcon
        fontSize="small"
        className={classes.dateIcon}
      />
      {content}
    </span>
  );
};
