import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const colors = {
  red: {
    900: "hsla(4, 97%, 12%, 1)",
    800: "hsla(4, 91%, 21%, 1)",
    700: "hsla(4, 88%, 35%, 1)",
    600: "hsla(4, 95%, 40%, 1)",
    500: "hsla(4, 96%, 52%, 1)",
    400: "hsla(4, 92%, 62%, 1)",
    300: "hsla(4, 96%, 71%, 1)",
    200: "hsla(4, 77%, 81%, 1)",
    100: "hsla(4, 96%, 91%, 1)",
  },
  orange: {
    900: "hsla(23, 94%, 13%, 1)",
    800: "hsla(23, 95%, 22%, 1)",
    700: "hsla(23, 93%, 30%, 1)",
    600: "hsla(23, 95%, 40%, 1)",
    500: "hsla(23, 95%, 53%, 1)",
    400: "hsla(23, 95%, 60%, 1)",
    300: "hsla(23, 95%, 68%, 1)",
    200: "hsla(22, 100%, 81%, 1)",
    100: "hsla(23, 96%, 90%, 1)",
  },
  yellow: {
    900: "hsla(44, 86%, 11%, 1)",
    800: "hsla(44, 100%, 24%, 1)",
    700: "hsla(44, 100%, 36%, 1)",
    600: "hsla(44, 100%, 43%, 1)",
    500: "hsla(44, 100%, 53%, 1)",
    400: "hsla(44, 100%, 64%, 1)",
    300: "hsla(44, 100%, 76%, 1)",
    200: "hsla(44, 100%, 86%, 1)",
    100: "hsla(45, 100%, 94%, 1)",
  },
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark: colors.red[800],
      main: colors.red[400],
      light: colors.red[100],
      contrastText: "#fff",
    },
    secondary: {
      main: grey[900],
    },
  },
  typography: {
    h1: {
      fontSize: "1.8rem",
      fontWeight: "400",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "400",
    },
    h3: {
      fontSize: "1.3rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        minWidth: "350px",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "#8787f5",
          borderWidth: "1px",
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#8787f5",
        },
      },
    },
    // Need to override select inputs separately because react-admin
    // uses a min-width rule on them.
    RaSelectInput: {
      input: {
        minWidth: "350px",
      },
    },
    RaLayout: {
      appFrame: {
        // overrides margin at top of the page. Should work for mobile too
        // so no need for separate media query.
        marginTop: "0px !important",
        backgroundColor: "hsl(0deg 0% 99%)",
      },
      content: {
        padding: "24px 48px !important",
      },
    },
    RaDatagrid: {
      headerCell: {
        fontWeight: 600,
      },
    },
    RaMenuItemLink: {
      active: {
        color: "#2f2e2ed6 !important",
        borderRight: `3px solid ${colors.red[400]}`,
        "& svg": {
          color: "#696969cf !important",
        },
      },
    },
    RaReferenceField: {
      link: {
        color: "#1a42d4",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    RaSidebar: {
      paper: {
        borderRight: "1px solid #80808057",
      },
      root: {
        boxShadow: "1px 0px 6px 0px #949494a1",
      },
    },
    "column-status": {
      padding: 0,
    },
  },
});
