import React, { useCallback } from "react";
import { useInput } from "react-admin";
import { format } from "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  // Add artificial margins to mimic react-admin input fields
  // Those fields have different margin on top and have a error-label
  // placeholder that needs to be accounted for on the bottom.
  datePicker: {
    marginBottom: "27px",
    marginTop: "8px",
  },
});

// Modelled after this component:
// https://github.com/vascofg/react-admin-date-inputs/blob/master/src/index.js
export const DateInput = (fieldProps) => {
  const classes = useStyles();
  const { options, label, source, className } = fieldProps;
  const { input, meta } = useInput({ source });
  const { touched, error } = meta;

  const dateFormat = "MM/dd/yyyy";

  const handleChange = useCallback(
    (value) => {
      Date.parse(value)
        ? input.onChange(format(value, dateFormat))
        : input.onChange(null);
    },
    [input]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...options}
        label={label}
        margin="normal"
        format={dateFormat}
        error={!!(touched && error)}
        helperText={touched && error}
        value={input.value || null}
        mask="__/__/____"
        onChange={(date) => handleChange(date)}
        inputVariant="outlined"
        disableToolbar
        size="small"
        className={`${className} ${classes.datePicker}`}
        InputLabelProps={{
          shrink: input.value ? true : undefined,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

DateInput.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
};
