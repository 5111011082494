import React from "react";

import {
  Card,
  Typography,
  makeStyles,
  Button,
  CardActions,
  CardContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import {
  useShowController,
  ReferenceField,
  FunctionField,
  Labeled,
  ReferenceManyField,
  Button as RaButton,
} from "react-admin";
import { StatusChipField } from "../components/StatusChipField";
import { AddNewNoteButton, NotesDisplay } from "./Notes";
import { AddNewStageButton } from "./Stages";
import { TasksList } from "./TaskShow";
import { DateField } from "../components/DateField";
import { FileOrImageField } from "../components/FileOrImageField";

export const ProjectContext = React.createContext(null);

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "32px 48px 0 48px",
    padding: "32px 48px",
  },
  section: {
    marginBottom: "24px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "8px",
  },
  title: {
    marginBottom: "8px",
  },
  projectId: {
    color: "#5f5f5f",
    "&:not(:last-child)": {
      marginBottom: "12px",
    },
  },
  dateLabel: {
    marginRight: "64px",
    minWidth: "0",
  },
  notesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  footer: {
    backgroundColor: "hsl(0deg 0% 96%)",
    padding: "12px 48px",
  },
  spacer: {
    flexGrow: "1",
  },
  tasksHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "48px",
    marginBottom: "16px",
  },
  addNoteButton: {
    marginTop: "4px",
  },
  colWidth: {
    maxWidth: "500px",
  },
  noFiles: {
    color: "grey",
    fontStyle: "italic",
    fontSize: ".9rem",
  },
  imageList: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "300px",
    overflow: "auto",
    backgroundColor: "#fcfcfc",
    width: "100%",
  },
}));

const CustomerPageLink = ({ record }) => {
  if (!record.id) {
    return;
  }

  const url = `${process.env.REACT_APP_CUSTOMER_APP_URL}/${record.id}`;

  return (
    <Button
      href={url}
      endIcon={<ExitToAppIcon />}
      rel="noopener noreferrer"
      target="_blank"
      variant="outlined"
    >
      View Customer Page
    </Button>
  );
};

const ImageDisplay = ({ record }) => {
  const classes = useStyles();

  return record.files && record.files.length ? (
    <div className={classes.imageList}>
      {record.files.map((f, index) => (
        <FileOrImageField
          source="path"
          title="title"
          record={f}
          primary={record.primary_image_id}
          key={index}
        />
      ))}
    </div>
  ) : (
    <span className={classes.noFiles}>No files for project</span>
  );
};

export const ProjectShow = (props) => {
  const { record, resource } = useShowController(props);

  const classes = useStyles();

  return record ? (
    <ProjectContext.Provider value={record.id}>
      <Card>
        <CardContent className={classes.container}>
          <div className={classes.section}>
            <div className={classes.header}>
              <div>
                <Typography variant="h1" className={classes.title}>
                  {record.name}
                </Typography>
                <Typography variant="body2" className={classes.projectId}>
                  {record.id}
                </Typography>
                {record.description && (
                  <Typography className={classes.colWidth}>
                    {record.description}
                  </Typography>
                )}
              </div>
              <div>
                <StatusChipField source="status" record={record} />
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <Labeled label="Customer">
              <ReferenceField
                source="customer_id"
                reference="customers"
                record={record}
              >
                <FunctionField
                  label="Name"
                  render={(record) =>
                    `${record.first_name} ${record.last_name}`
                  }
                />
              </ReferenceField>
            </Labeled>
          </div>

          <div className={classes.section}>
            <Labeled className={classes.dateLabel} label="Started">
              <DateField source="date_started" record={record} />
            </Labeled>
            <Labeled className={classes.dateLabel} label="Completed">
              <DateField source="date_completed" record={record} />
            </Labeled>
          </div>

          <div className={classes.section}>
            <div className={`${classes.notesContainer} ${classes.colWidth}`}>
              <Labeled label="Notes">
                <ReferenceManyField
                  label="Notes"
                  reference="notes"
                  target="project_id"
                  record={record}
                >
                  <NotesDisplay type="project" />
                </ReferenceManyField>
              </Labeled>
              <div className={classes.addNoteButton}>
                <AddNewNoteButton record={record} />
              </div>
            </div>
          </div>

          <div className={classes.section}>
            <Labeled label="Files">
              <ImageDisplay record={record} />
            </Labeled>
          </div>
        </CardContent>
        <CardActions className={classes.footer}>
          <div>
            <CustomerPageLink record={record} />
          </div>
          <span className={classes.spacer}></span>
          <RaButton
            label="Edit Project"
            // variant="contained"
            variant="outlined"
            color="primary"
            component={Link}
            to={{
              pathname: `/${resource}/${record.id}`,
            }}
          >
            <EditIcon />
          </RaButton>
        </CardActions>
      </Card>
      <div className={classes.tasksHeader}>
        <Typography variant="h1">Tasks</Typography>
        <AddNewStageButton />
      </div>
      <ReferenceManyField
        reference="tasks"
        target="project_id"
        addLabel={false}
        sort={{ field: "created_at", order: "ASC" }}
        record={record}
      >
        <TasksList />
        {/* <StagesListDisplay /> */}
      </ReferenceManyField>
    </ProjectContext.Provider>
  ) : null;
};
